import { FC } from 'react'

import { Box, ConditionsModal } from '@dltru/dfa-ui'

import { AgreementSection } from './components/AgreementSection'
import { AgreementSectionList } from './components/AgreementSectionList'
import './style.less'
import { IAgreementModal } from './types'

export const PrivacyAgreement: FC<IAgreementModal> = ({ ...props }) => {
    return (
        <ConditionsModal
            title={<Box align="center">ПОЛИТИКА ОБРАБОТКИ ПЕРСОНАЛЬНЫХ ДАННЫХ</Box>}
            width={740}
            {...props}
            text={
                <Box padding={[0, 0, 0, 48]}>
                    <AgreementSectionList>
                        <AgreementSection title={'ОБЩИЕ ПОЛОЖЕНИЯ'}>
                            <li>
                                Настоящая Политика обработки персональных данных в ООО «Системы
                                распределенного реестра» (далее — «Политика») определяет основные
                                вопросы, связанные с обработкой персональных данных с использованием
                                средств автоматизации, в том числе в
                                информационно-телекоммуникационных сетях, или без использования
                                таких средств, если обработка персональных данных без использования
                                таких средств соответствует характеру действий (операций),
                                совершаемых с персональными данными с использованием средств
                                автоматизации, то есть позволяет осуществлять в соответствии с
                                заданным алгоритмом поиск персональных данных, зафиксированных на
                                материальном носителе, и содержащихся в картотеках или иных
                                систематизированных собраниях персональных данных, и (или) доступ к
                                таким персональным данным.
                            </li>
                            <li>
                                {' '}
                                Политика разработана в соответствии с законодательством Российской
                                Федерации применимому к сбору хранению и обработке персональных
                                данных.
                            </li>
                            <li>
                                {' '}
                                Целью Политики является установление принципов и подходов к
                                обработке персональных данных в ООО «Системы распределенного
                                реестра».
                            </li>
                            <li>
                                {' '}
                                Политика распространяется на любое действие (операцию) или
                                совокупность действий (операций), совершаемых с использованием
                                средств автоматизации или без использования таких средств с
                                персональными данными, включая сбор, запись, систематизацию,
                                накопление, хранение, уточнение (обновление, изменение), извлечение,
                                использование, передача (распространение, предоставление, доступ),
                                блокирование, удаление, уничтожение персональных данных.
                            </li>
                            <li>
                                {' '}
                                Политика является общедоступным документом и размещается на сайте.
                            </li>
                        </AgreementSection>
                        <AgreementSection title="ТЕРМИНЫ И ОПРЕДЕЛЕНИЯ">
                            <li>
                                В Политике, если из текста прямо не вытекает иное, следующие термины
                                будут иметь указанные ниже значения:
                                <ol className="subparagraph-list">
                                    <li>
                                        {' '}
                                        автоматизированная обработка персональных данных обработка
                                        персональных данных с помощью средств вычислительной
                                        техники;
                                    </li>
                                    <li>
                                        {' '}
                                        блокирование - временное прекращение обработки персональных
                                        данных (за исключением случаев, если обработка необходима
                                        для уточнения персональных данных);
                                    </li>
                                    <li>
                                        {' '}
                                        безопасность персональных данных состояние защищенности
                                        характеризуемое способностью пользователей, технических
                                        средств и информационных технологий обеспечить
                                        конфиденциальность, целостность и доступность персональных
                                        данных при их обработке в информационных системах
                                        персональных данных;
                                    </li>
                                    <li>
                                        {' '}
                                        закон №152-ФЗ - Федеральный закон от 27.07.2006 № 152-ФЗ «О
                                        персональных данных»;
                                    </li>
                                    <li>
                                        {' '}
                                        информационная система - совокупность содержащихся в базах
                                        данных персональных данных и обеспечивающих их обработку
                                        информационных технологий и технических средств;
                                    </li>
                                    <li>
                                        {' '}
                                        информационная система для целей ЦФА – информационная
                                        система в которой осуществляется выпуск цифровых финансовых
                                        активов, создаваемая и эксплуатируемая на базе программы для
                                        ЭВМ «Платформа для выпуска и обмена цифровых финансовых
                                        активов», по сути представляющая собой совокупность
                                        содержащейся в базе данных информации о выпуске, учете и
                                        обращении ЦФА и обеспечивающих ее обработку информационных
                                        технологий и технических средств, в том числе на основе
                                        Распределенного реестра, эксплуатируемых Компанией.
                                    </li>
                                    <li>
                                        {' '}
                                        обезличивание - действия, в результате которых становится
                                        невозможным без использования дополнительной информации
                                        определить принадлежность персональных данных конкретному
                                        субъекту персональных данных;
                                    </li>
                                    <li>
                                        {' '}
                                        обработка персональных данных - любое действие (операция)
                                        или совокупность действий (операций), совершаемых с
                                        использованием средств автоматизации или без использования
                                        таких средств с персональными данными, а также путем
                                        смешанной обработки, включая сбор, запись, систематизацию,
                                        накопление, хранение, уточнение (обновление, изменение),
                                        извлечение, использование, передачу (распространение,
                                        предоставление, доступ) и поручение обработки третьим лицам,
                                        получение от третьих лиц, обезличивание, блокирование,
                                        удаление, уничтожение персональных данных;
                                    </li>
                                    <li>
                                        {' '}
                                        . онлайн-сервис — действия Компании как оператора
                                        информационной системы для целей ЦФА и (или) как оператора
                                        обмена (в соответствии с Федеральным законом "О цифровых
                                        финансовых активах, цифровой валюте и о внесении изменений в
                                        отдельные законодательные акты Российской Федерации" от
                                        31.07.2020 N 259-ФЗ), а так же иные действия Компании
                                        товары, работы, услуги, программное обеспечение, права на
                                        охраняемые результаты интеллектуальной деятельности, иные
                                        блага, реализуемые Компанией с использованием
                                        информационно-телекоммуникационной сети «Интернет», в том
                                        числе посредством Сайта.
                                    </li>

                                    <li>
                                        {' '}
                                        ответственный за организацию обработки персональных данных
                                        назначенный Компанией работник, в чьи должностные
                                        обязанности входит осуществление функций по организации
                                        обработки персональных данных;
                                    </li>
                                    <li>
                                        {' '}
                                        конфиденциальность персональных данных - обязательное для
                                        соблюдения Компанией или иным лицом, получившим доступ к
                                        персональным данным, требование не раскрывать третьим лицам
                                        и не распространять персональные данные без согласия
                                        субъекта персональных данных или наличия иного законного
                                        основания, предусмотренного федеральным законом;
                                    </li>
                                    <li> Компания — ООО «Системы распределенного реестра»;</li>
                                    <li>
                                        {' '}
                                        персональные данные - любая информация, относящаяся к прямо
                                        или косвенно определенному или определяемому физическому
                                        лицу (субъекту персональных данных);
                                    </li>
                                    <li>
                                        {' '}
                                        разрешенные субъектом персональных данных для
                                        распространения - персональные данные, доступ
                                        неограниченного круга лиц к которым предоставлен субъектом
                                        персональных данных путем дачи согласия на обработку
                                        персональных данных, разрешенных субъектом персональных
                                        данных для распространения в порядке, предусмотренном
                                        Федеральным законом № 152-ФЗ;
                                    </li>
                                    <li>
                                        {' '}
                                        специальная категория персональных данных — сведения
                                        касающиеся расовой, национальной принадлежности,
                                        политических взглядов, религиозных или философских
                                        убеждений, состояния здоровья, интимной жизни;
                                    </li>
                                    <li>
                                        {' '}
                                        предоставление - действия, направленные на раскрытие
                                        персональных данных определенному лицу или определенному
                                        кругу лиц;
                                    </li>
                                    <li>
                                        {' '}
                                        распространение - действия, направленные на раскрытие
                                        персональных данных неопределенному кругу лиц;
                                    </li>
                                    <li>
                                        {' '}
                                        субъект персональных данных - физическое лицо, прямо или
                                        косвенно определенное или определяемое на основании
                                        относящихся к нему персональных данных;
                                    </li>
                                    <li>
                                        {' '}
                                        уничтожение персональных данных - действия, в результате
                                        которых становится невозможным восстановить содержание
                                        персональных данных в информационной системе персональных
                                        данных и (или) в результате которых уничтожаются
                                        материальные носители персональных данных;
                                    </li>
                                    <li>
                                        {' '}
                                        идентификация – совокупность мероприятий по установлению
                                        сведений о пользователях, их представителях,
                                        выгодоприобретателях, бенефициарных владельцах и
                                        подтверждению достоверности этих сведений с использованием
                                        оригиналов документов и (или) надлежащим образом заверенных
                                        копий и (или) государственных и иных информационных систем,
                                        и сопоставлению данных сведений с уникальным обозначением
                                        (обозначениями) сведений о лице, необходимым для определения
                                        такого лица;{' '}
                                    </li>
                                    <li>
                                        {' '}
                                        пользователь – юридическое лицо, физическое лицо или
                                        физическое лицо, являющееся индивидуальным предпринимателем,
                                        использующее информационную систему для целей ЦФА согласно
                                        правилам такой информационной системы и включенное в реестр
                                        пользователей.
                                    </li>
                                    <li>
                                        {' '}
                                        представитель пользователя – физическое лицо, действующее от
                                        имени пользователя в информационной системе для целей ЦФА.{' '}
                                    </li>
                                    <li>
                                        {' '}
                                        реестр пользователей – являющаяся частью информационной
                                        системы для целей ЦФА совокупность информации, включающей
                                        сведения о пользователях, в том числе, сведения, необходимые
                                        для аутентификации пользователей в информационной системе
                                        для целей ЦФА, сведения о том, в каком статусе или статусах
                                        пользователи аутентифицированы в информационной системе для
                                        целей ЦФА. Порядок ведения реестра определяется Правилами
                                        информационной системы для целей ЦФА.
                                    </li>
                                    <li>
                                        {' '}
                                        правила информационной системы для целей ЦФА - правила
                                        информационной системы ООО «Системы распределенного
                                        реестра», согласованные Банком России в порядке,
                                        предусмотренном законодательством Российской Федерации;
                                    </li>
                                    <li>
                                        {' '}
                                        техническая информация — информация, связанная с субъектами,
                                        которая автоматически передается Компании в процессе
                                        использования Сайта с помощью программного обеспечения,
                                        установленного на устройстве пользователя (браузера и тому
                                        подобное), в том числе, IР-адрес, файлы cookies;
                                    </li>
                                    <li>
                                        {' '}
                                        файлы cookies — доступные Компании текстовые файлы для
                                        обработки информации об активности пользователя на Сайте
                                        (время посещения, посещаемые страницы и так далее);
                                    </li>
                                    <li>
                                        {' '}
                                        сайт — сайт Компании в информационно-телекоммуникационной
                                        сети «Интернет», доступ к которому осуществляется по
                                        доменному имени ”www.masterchain.ru”.
                                    </li>
                                </ol>
                            </li>
                            <li>
                                Иные термины, не упомянутые выше, используются в значении,
                                установленном нормативными правовыми актами Российской Федерации,
                                документами и локальными актами Компании.
                            </li>
                        </AgreementSection>
                        <AgreementSection title="КАТЕГОРИИ СУБЪЕКТОВ ПЕРСОНАЛЬНЫХ ДАННЫХ И ЦЕЛИ ОБРАБОТКИ ПЕРСОНАЛЬНЫХ ДАННЫХ">
                            <li>
                                Компания осуществляет обработку персональных данных следующих
                                субъектов:
                                <ol className="subparagraph-list">
                                    <li>
                                        {' '}
                                        физические лица, состоящие (состоявшие) в трудовых
                                        отношениях с Компанией или имеющие (имевших) намерение
                                        вступить в трудовые отношения с Компанией, близкие
                                        родственники указанных лиц;
                                    </li>
                                    <li>
                                        {' '}
                                        физические лица, обратившиеся в Компанию или вступившие в
                                        переговоры с Компанией как от собственного имени, так в
                                        интересах третьих лиц с целью получения или предоставления
                                        информации, заключения (изменения, расторжения) договора
                                        либо вступления в иные гражданско-правовые отношения с
                                        Компанией (лицом, интересы которого представляет Компания),
                                        а также физические лица, от имени или в интересах которых
                                        выступают субъекты персональных данных, указанные в
                                        настоящем пункте;
                                    </li>
                                    <li>
                                        {' '}
                                        физические лица, состоящие в договорных отношениях с
                                        Компанией;
                                    </li>
                                    <li>
                                        {' '}
                                        физические лица, являющиеся — представителями (в том числе
                                        законными) или работниками лиц, состоящих (состоявших) в
                                        договорных отношениях с Компанией;
                                    </li>
                                    <li>
                                        {' '}
                                        физические лица - члены органов управления Компании,
                                        физические лица, являющиеся аффилированными лицами Компании;
                                    </li>
                                    <li>
                                        {' '}
                                        физические лица, не состоящие с Компанией в трудовых или
                                        гражданско-правовых отношениях, проходящие в помещения
                                        Компании;
                                    </li>
                                    <li>
                                        {' '}
                                        физические лица – пользователи и (или) представители
                                        пользователей, ровно, как и выгодоприобретатели
                                        пользователей, их бенефициарные владельцы;{' '}
                                    </li>
                                    <li>
                                        {' '}
                                        иные физические лица, чьи персональные данные обрабатываются
                                        Компанией в соответствии с требованиями законодательства
                                        Российской Федерации;
                                    </li>
                                </ol>
                            </li>
                            <li>
                                Обработка персональных данных в информационных системах персональных
                                данных осуществляется в целях ведения Компанией предпринимательской
                                деятельности, в том числе:
                                <ol className="subparagraph-list">
                                    <li>
                                        {' '}
                                        исполнение обязанностей оператора информационной системы для
                                        целей ЦФА и (или) оператора обмена, согласно
                                        законодательству РФ и правилам информационной системы для
                                        целей ЦФА;
                                    </li>
                                    <li>
                                        {' '}
                                        идентификации физических лиц (в том числе пользователей) для
                                        целей их допуска к использованию информационной системы для
                                        целей ЦФА Компании и (или) для иной деятельности Компании;
                                    </li>
                                    <li>
                                        заключение, исполнение и прекращение гражданско-правовых
                                        договоров с физическими лицами (гражданами, в том числе
                                        иностранными, индивидуальными предпринимателями,
                                        юридическими лицами;
                                    </li>
                                    <li>
                                        {' '}
                                        привлечение кандидатов и принятие решения о возможности
                                        заключения трудового договора с соискателями на вакантные
                                        должности в Компании;
                                    </li>
                                    <li>
                                        {' '}
                                        исполнение трудового законодательства в рамках трудовых и
                                        иных непосредственно связанных с ним отношений, в том числе:
                                        содействие работникам в трудоустройстве, получении
                                        образования и продвижении по службе, обеспечение личной
                                        безопасности работников, контроль количества и качества
                                        выполняемой работы, обеспечение сохранности имущества,
                                        ведение кадрового и бухгалтерского учета, заполнение и
                                        передача в уполномоченные органы требуемых форм отчетности,
                                        организация постановки на индивидуальный
                                        (персонифицированный) учет работников в системах
                                        обязательного пенсионного страхования и обязательного
                                        социального страхования;
                                    </li>
                                    <li>
                                        {' '}
                                        предоставление информации о Компании, ее продуктах и
                                        услугах, разработка новых и (или) усовершенствования
                                        существующих продуктов и (или) услуг Компании;
                                    </li>
                                    <li>
                                        {' '}
                                        обеспечение функционирования и улучшение работы
                                        Онлайн-сервисов, в том числе ведение статистики о
                                        пользователях, хранение персональных предпочтений и настроек
                                        пользователей, отслеживание состояния сессии доступа
                                        пользователей, получение от пользователей обратной связи в
                                        отношении Онлайн-сервисов;
                                    </li>
                                    <li>
                                        {' '}
                                        выполнения требований законодательства Российской Федерации,
                                        в том числе, но не ограничиваясь, Федерального закона «О
                                        противодействии легализации (отмыванию) доходов, полученных
                                        преступным путем, и финансированию терроризма», Федерального
                                        закона «О цифровых финансовых активах, цифровой валюте и о
                                        внесении изменений в отдельные законодательные акты
                                        Российской Федерации», и нормативных актов Банка России;
                                    </li>
                                    <li>
                                        {' '}
                                        обеспечения охраны помещений, ценностей и оборудования
                                        Компании, контроля и управления доступом на территорию
                                        Компании;
                                    </li>
                                </ol>
                            </li>
                            <li>
                                Компания обрабатывает данные, собранные с помощью файлов cookies,
                                для статистических целей, для диагностики трудностей с сервером и
                                для целей администрирования Сайта.
                            </li>
                        </AgreementSection>
                        <AgreementSection title="ПРИНЦИПЫ ОБРАБОТКИ ПЕРСОНАЛЬНЫХ ДАННЫХ">
                            <li>
                                {' '}
                                Обработка персональных данных должна осуществляться на законной и
                                справедливой основе.
                            </li>
                            <li>
                                {' '}
                                Обработка персональных данных должна ограничиваться достижением
                                конкретных, заранее определенных и законных целей. Не допускается
                                обработка персональных данных, несовместимая с целями сбора
                                персональных данных.
                            </li>
                            <li>
                                {' '}
                                Не допускается объединение баз данных, содержащих персональные
                                данные, обработка которых осуществляется в целях, несовместимых
                                между собой.
                            </li>
                            <li>
                                {' '}
                                Обработке подлежат только персональные данные, которые отвечают
                                целям их обработки.
                            </li>
                            <li>
                                {' '}
                                Содержание и объем обрабатываемых персональных данных должны
                                соответствовать заявленным целям обработки. Обрабатываемые
                                персональные данные не должны быть избыточными по отношению к
                                заявленным целям их обработки.{' '}
                            </li>
                            <li>
                                {' '}
                                При обработке персональных данных должны быть обеспечены точность
                                персональных данных, их достаточность, а в необходимых случаях и
                                актуальность по отношению к целям обработки персональных данных.
                                Компания принимает необходимые меры либо обеспечивает их принятие по
                                удалению или уточнению неполных или неточных данных.
                            </li>
                            <li>
                                {' '}
                                Хранение персональных данных должно осуществляться в форме,
                                позволяющей определить субъекта персональных данных, не дольше, чем
                                этого требуют цели обработки персональных данных, если срок хранения
                                персональных данных не установлен федеральным законом, договором,
                                стороной которого, выгодоприобретателем или поручителем по которому
                                является субъект персональных данных. Обрабатываемые персональные
                                данные подлежат уничтожению либо обезличиванию по достижении целей
                                обработки или в случае утраты необходимости в достижении этих целей,
                                если иное не предусмотрено федеральным законом.
                            </li>
                        </AgreementSection>
                        <AgreementSection title="УСЛОВИЯ ОБРАБОТКИ ПЕРСОНАЛЬНЫХ ДАННЫХ">
                            <li>
                                Обработка персональных данных осуществляется с соблюдением принципов
                                и правил, установленных Законом № 152-ФЗ. Обработка персональных
                                данных допускается в следующих случаях:
                                <ol className="subparagraph-list">
                                    <li>
                                        {' '}
                                        обработка персональных данных осуществляется с согласия
                                        субъекта персональных данных на обработку его персональных
                                        данных;
                                    </li>
                                    <li>
                                        {' '}
                                        обработка персональных данных необходима для достижения
                                        целей, предусмотренных международным договором Российской
                                        Федерации или законом, для осуществления и выполнения
                                        возложенных законодательством Российской Федерации на
                                        Компанию функций, полномочий и обязанностей;
                                    </li>
                                    <li>
                                        обработка персональных данных, включая их передачу третьим
                                        лицам, когда такая обработка необходима для достижения
                                        целей, обоснованных осуществлением и выполнением возложенных
                                        законодательством Российской Федерации на Компанию функций,
                                        полномочий и обязанностей, в том числе, когда возложение
                                        обязанностей стало следствием заключения (и / или исполнения
                                        соглашения с субъектом персональных данных)
                                    </li>
                                    <li>
                                        {' '}
                                        обработка персональных данных осуществляется в связи с
                                        участием Компании в конституционном, гражданском,
                                        административном, уголовном судопроизводстве,
                                        судопроизводстве в арбитражных судах;
                                    </li>
                                    <li>
                                        {' '}
                                        обработка персональных данных необходима для исполнения
                                        судебного акта, акта другого органа или должностного лица,
                                        подлежащих исполнению в соответствии с законодательством
                                        Российской Федерации об исполнительном производстве;{' '}
                                    </li>
                                    <li>
                                        {' '}
                                        обработка персональных данных необходима для исполнения
                                        договора, стороной которого либо выгодоприобретателем или
                                        поручителем по которому является субъект персональных
                                        данных, а также для заключения договора по инициативе
                                        субъекта персональных данных или договора, по которому
                                        субъект персональных данных будет являться
                                        выгодоприобретателем или поручителем;
                                    </li>
                                    <li>
                                        {' '}
                                        обработка персональных данных необходима для защиты жизни,
                                        здоровья или иных жизненно важных интересов субъекта
                                        персональных данных, если получение согласия субъекта
                                        персональных данных невозможно;
                                    </li>
                                    <li>
                                        {' '}
                                        обработка персональных данных необходима для осуществления
                                        прав и законных интересов Компании или третьих лиц, либо для
                                        достижения общественно значимых целей при условии, что при
                                        этом не нарушаются права и свободы субъекта персональных
                                        данных;
                                    </li>
                                    <li>
                                        {' '}
                                        обработка персональных данных осуществляется в
                                        статистических или иных исследовательских целях, за
                                        исключением целей, указанных в статье 15 Закона №152-ФЗ, при
                                        условии обязательного обезличивания персональных данных;
                                    </li>
                                    <li>
                                        {' '}
                                        осуществляется обработка персональных данных, подлежащих
                                        опубликованию или обязательному раскрытию в соответствии с
                                        законодательством РФ.
                                    </li>
                                </ol>
                            </li>
                            <li>
                                Компания осуществляет обработку персональных данных с использованием
                                средств автоматизации и без использования средств автоматизации, а
                                также смешанную обработку, включая сбор, запись, систематизацию,
                                накопление, хранение, уточнение (обновление, изменение), извлечение,
                                анализ и использование, передачу (распространение, предоставление,
                                доступ) и поручение обработки третьим лицам, получение от третьих
                                лиц, обезличивание, блокирование, удаление, уничтожение персональных
                                данных в сроки, необходимые для достижения целей обработки
                                персональных данных.
                            </li>
                            <li>
                                {' '}
                                В Компании запрещается принятие решений на основании исключительно
                                автоматизированной обработки персональных данных, которые порождают
                                юридические последствия в отношении субъекта персональных данных,
                                или иным образом затрагивают его права и законные интересы, кроме
                                случаев и условий, предусмотренных законодательством Российской
                                Федерации в области персональных данных.
                            </li>
                            <li>
                                Перечень персональных данных, обрабатываемых в Компании,
                                определяется в соответствии с законодательством Российской Федерации
                                и внутренними документами Компании.
                            </li>
                            <li>
                                Компания может включать персональные данные субъектов персональных
                                данных в общедоступные источники персональных данных в соответствии
                                с законодательством Российской Федерации, либо в случае получения
                                письменного согласия субъекта персональных данных.
                            </li>
                            <li>
                                Компания не осуществляет обработку специальных категорий
                                персональных данных и биометрических персональных данных.
                            </li>
                            <li>Трансграничная передача персональных данных не осуществляется.</li>
                            <li>
                                В случае если Компания поручает обработку персональных данных
                                другому лицу, ответственность перед субъектом персональных данных за
                                действия указанного лица несет Компания. Лицо, осуществляющее
                                обработку персональных данных по поручению Компании, несет
                                ответственность перед Компанией.
                            </li>
                            <li>
                                {' '}
                                Компания обязуется и обязывает иных лиц, получивших доступ к
                                персональным данным, не раскрывать третьим лицам и не распространять
                                персональные данные без согласия субъекта персональных данных, если
                                иное не предусмотрено федеральным законом.
                            </li>
                            <li>
                                Представители уполномоченных органов власти (в том числе,
                                контролирующих, надзорных, правоохранительных, дознания и следствия)
                                получают доступ к персональным данным, обрабатываемым в Компании, в
                                объеме и порядке, установленном законодательством Российской
                                Федерации.
                            </li>
                        </AgreementSection>
                        <AgreementSection title="ПРАВА СУБЪЕКТА ПЕРСОНАЛЬЊIХДАНЊIХ">
                            <li>
                                Субъект персональных данных имеет право на получение информации,
                                касающейся обработки его персональных данных, в том числе
                                содержащей:
                                <ol className="subparagraph-list">
                                    <li>
                                        подтверждение факта обработки персональных данных Компанией;{' '}
                                    </li>
                                    <li>
                                        {' '}
                                        правовые основания и цели обработки персональных данных;
                                    </li>
                                    <li>
                                        {' '}
                                        цели и применяемые Компанией способы обработки персональных
                                        данных;
                                    </li>
                                    <li>
                                        {' '}
                                        наименование и место нахождения Компании, сведения о лицах
                                        (за исключением работников Компании), которые имеют доступ к
                                        персональным данным или которым могут быть переданы или
                                        раскрыты персональные данные на основании договора с
                                        Компанией или на основании федерального закона;
                                    </li>
                                    <li>
                                        {' '}
                                        обрабатываемые персональных данных, относящихся к
                                        соответствующему субъекту персональных данных, источник их
                                        получения, если иной порядок представления таких данных не
                                        предусмотрен федеральным законом;
                                    </li>
                                    <li>
                                        {' '}
                                        информацию об осуществленной или о предполагаемой
                                        трансграничной передаче данных;
                                    </li>
                                    <li>
                                        {' '}
                                        сроки обработки персональных данных, в том числе сроки их
                                        хранения;
                                    </li>
                                    <li>
                                        {' '}
                                        порядок осуществления субъектом персональных данных прав,
                                        предусмотренных Законом № 152-ФЗ;
                                    </li>
                                    <li>
                                        {' '}
                                        наименование или фамилию, имя, отчество и адрес лица,
                                        осуществляющего обработку персональных данных по поручению
                                        Компании, если обработка поручена или будет поручена такому
                                        лицу;
                                    </li>
                                    <li>
                                        {' '}
                                        информацию о способах исполнения Компанией обязанностей,
                                        установленных статьей 18.1 Закона №152-ФЗ
                                    </li>
                                    <li>
                                        {' '}
                                        иные сведения, предусмотренные Законом №152-ФЗ или другими
                                        федеральными законами.
                                    </li>
                                </ol>
                            </li>
                            <li>
                                Право субъекта персональных данных на доступ к его персональным
                                данным может быть ограничено в соответствии с федеральными законами,
                                в том числе если:
                                <ol className="subparagraph-list">
                                    <li>
                                        обработка персональных данных осуществляется в соответствии
                                        с законодательством о противодействии легализации
                                        (отмыванию) доходов, полученных преступным путем, и
                                        финансированию терроризма;
                                    </li>
                                    <li>
                                        доступ субъекта персональных данных к его персональным
                                        данным нарушает права и законные интересы третьих лиц.
                                    </li>
                                </ol>
                            </li>

                            <li>
                                Субъект персональных данных вправе требовать от Компании уточнения
                                его персональных данных, их блокирования или уничтожения в случае,
                                если персональные данные являются неполными, устаревшими, неточными,
                                незаконно полученными или не являются необходимыми для заявленной
                                цели обработки, а также принимать предусмотренные Законом №152-ФЗ
                                меры по защите своих прав.
                            </li>
                            <li>
                                {' '}
                                Согласие на обработку персональных данных может быть отозвано
                                субъектом персональных данных.
                            </li>
                            <li>
                                {' '}
                                Субъект персональных имеет право потребовать прекратить обработку
                                его персональных данных в целях продвижения товаров, работ, услуг
                                Компании на рынке.
                            </li>
                            <li>
                                {' '}
                                Субъект персональных данных имеет право обратиться в Компанию с
                                требованием прекратить передачу (распространение, предоставление,
                                доступ) своих персональных данных, ранее разрешенных Субъектом
                                персональных данных для распространения.
                            </li>
                            <li>
                                {' '}
                                Субъект персональных данных вправе обжаловать действия или
                                бездействие Компании в Федеральную службу по надзору в сфере связи,
                                информационных технологий и массовых коммуникаций (Роскомнадзор) или
                                в судебном порядке в случае, если субъект персональных данных
                                считает, что Компания осуществляет обработку его персональных данных
                                с нарушением требований Закона № 152-ФЗ или иным образом нарушает
                                его права и свободы.
                            </li>
                            <li>
                                {' '}
                                Для реализации своих прав и защиты законных интересов субъект
                                персональных данных имеет право письменно обратиться в Компанию.
                                Компания рассматривает любые письменные обращения и жалобы со
                                стороны субъектов персональных данных, тщательно расследует факты
                                нарушений и принимает все необходимые меры для их немедленного
                                устранения, наказания виновных лиц и урегулирования спорных ситуаций
                                в досудебном порядке.
                            </li>
                            <li>
                                {' '}
                                Письменный запрос субъекта персональных данных о предоставлении
                                информации, касающейся обработки его персональных данных, должен
                                содержать следующие обязательные реквизиты, установленные частью 3
                                статьи 14 Закона №152-ФЗ , в том числе: номер основного документа,
                                удостоверяющего личность субъекта персональных данных или его
                                представителя, сведения о дате выдачи указанного документа и
                                выдавшем его органе, сведения, подтверждающие участие субъекта
                                персональных данных в отношениях с Компанией, либо сведения, иным
                                образом подтверждающие факт обработки персональных данных Компанией,
                                подпись субъекта персональных данных или его представителя. Запрос
                                может быть направлен в форме электронного документа и подписан
                                электронной подписью в соответствии с законодательством Российской
                                Федерации.{' '}
                            </li>

                            <li>
                                {' '}
                                Запросы субъекта персональных данных для получения им сведений о
                                своих персональных данных, ознакомления с ними, для их уточнения,
                                блокирования или уничтожения, отзыва согласия на их обработку могут
                                быть направлены по адресу электронной почты или по адресу: 121099,
                                г. Москва, ул. Композиторская, д. 17, этаж 7, помещение 1, комнаты
                                11-17,{' '}
                            </li>
                            <li>
                                {' '}
                                . Субъект персональных данных имеет также иные права, установленные
                                законодательством Российской Федерации.
                            </li>
                        </AgreementSection>
                        <AgreementSection title="ОБЯЗАННОСТИ КОМПАНИИ ПРИ СБОРЕ ПЕРСОНАЛЬНЫХ ДАННЫХ">
                            <li>
                                {' '}
                                При сборе персональных данных Компания обязана предоставить субъекту
                                персональных данных по его просьбе информацию, предусмотренную
                                частью 7 статьи 14 Закона №152-ФЗ.
                            </li>
                            <li>
                                {' '}
                                Если в соответствии с федеральным законом предоставление
                                персональных данных и (или) получение Компанией согласия на
                                обработку персональных данных являются обязательными, Компания
                                обязана разъяснить субъекту персональных данных юридические
                                последствия отказа предоставить его персональные данные и (или) дать
                                согласие на их обработку.
                            </li>
                            <li>
                                {' '}
                                Если персональные данные получены не от субъекта персональных
                                данных, Компания, за исключением случаев, предусмотренных
                                соответствующими пунктами настоящей Политики, до начала обработки
                                таких персональных данных обязана предоставить субъекту персональных
                                данных следующую информацию:{' '}
                                <ol className="subparagraph-list">
                                    <li>
                                        {' '}
                                        наименование и адрес Компании или наименование либо фамилия,
                                        имя, отчество и адрес представителя Компании;{' '}
                                    </li>
                                    <li>
                                        {' '}
                                        цель обработки персональных данных и ее правовое основание;
                                    </li>
                                    <li> перечень персональных данных;</li>
                                    <li> предполагаемые пользователи персональных данных;</li>
                                    <li>
                                        {' '}
                                        установленные Законом № 152-ФЗ права субъекта персональных
                                        данных;
                                    </li>
                                </ol>
                            </li>
                            <li>
                                7.4. Компания освобождается от обязанности предоставить субъекту
                                персональных данных сведения, предусмотренные соответствующими
                                пунктами настоящей , в случаях, если:
                                <ol className="subparagraph-list">
                                    <li>
                                        субъект персональных данных уведомлен об осуществлении
                                        обработки его персональных данных Компанией;
                                    </li>
                                    <li>
                                        {' '}
                                        персональные данные получены Компанией на основании Закона
                                        №152ФЗ или в связи с исполнением договора, стороной которого
                                        либо выгодоприобретателем или поручителем по которому
                                        является субъект персональных данных;
                                    </li>
                                    <li>
                                        {' '}
                                        обработка персональных данных, разрешенных субъектом
                                        персональных данных для распространения, осуществляется с
                                        соблюдением запретов и условий, предусмотренных статьей 10.1
                                        Закона № 152-ФЗ;{' '}
                                    </li>
                                    <li>
                                        {' '}
                                        Компания осуществляет обработку персональных данных для
                                        статистических или иных исследовательских целей, если при
                                        этом не нарушаются права и законные интересы субъекта
                                        персональных данных;
                                    </li>
                                    <li>
                                        {' '}
                                        предоставление субъекту персональных данных сведений,
                                        предусмотренных соответствующими пунктами настоящей
                                        Политики, нарушает права и законные интересы третьих лиц.
                                    </li>
                                </ol>
                            </li>
                            <li>
                                При сборе персональных данных, в том числе посредством информационно
                                телекоммуникационной сети «Интернет», Компания обязана обеспечить
                                запись, систематизацию, накопление, хранение, уточнение (обновление,
                                изменение), извлечение персональных данных граждан Российской
                                Федерации с использованием баз данных, находящихся на территории
                                Российской Федерации, за исключением случаев, предусмотренных
                                Законом № 152-ФЗ.
                            </li>
                        </AgreementSection>
                        <AgreementSection title="МЕРЫ, НАПРАВЛЕННЫЕ НА ОБЕСПЕЧЕНИЕ ВЫПОЛНЕНИЯ ОБЯЗАННОСТЕЙ КОМПАНИИ">
                            <li>
                                Компания принимает меры, необходимые и достаточные для обеспечения
                                выполнения обязанностей, предусмотренных Законом №152-ФЗ и принятыми
                                в соответствии с ним нормативными правовыми актами. Компания
                                самостоятельно определяет состав и перечень мер, необходимых и
                                достаточных для обеспечения выполнения обязанностей, предусмотренных
                                Законом №152-ФЗ и принятыми в соответствии с ним нормативными
                                правовыми актами, если иное не предусмотрено другими федеральными
                                законами. К таким мерам относится:
                                <ol className="subparagraph-list">
                                    <li>
                                        {' '}
                                        назначение лица, ответственного за организацию обработки
                                        персональных данных;
                                    </li>
                                    <li>
                                        {' '}
                                        издание Компанией документов, определяющих политику Компании
                                        в отношении обработки персональных данных, локальных актов
                                        по вопросам обработки персональных данных, определяющих для
                                        каждой цели обработки персональных данных категории и
                                        перечень обрабатываемых персональных данных, категории
                                        субъектов, персональные данные которых обрабатываются,
                                        способы, сроки их обработки и хранения, порядок уничтожения
                                        персональных данных при достижении целей их обработки или
                                        при наступлении иных законных оснований, а также локальных
                                        актов, устанавливающих процедуры, направленные на
                                        предотвращение и выявление нарушений законодательства
                                        Российской Федерации, устранение последствий таких
                                        нарушений. Такие документы и локальные акты не могут
                                        содержать положения, ограничивающие права субъектов
                                        персональных данных, а также возлагающие на Компанию не
                                        предусмотренные законодательством Российской Федерации
                                        полномочия и обязанности;
                                    </li>
                                    <li>
                                        {' '}
                                        применение правовых, организационных и технических мер по
                                        обеспечению безопасности персональных данных в соответствии
                                        со статьей 19 Закона №152-ФЗ;
                                    </li>
                                    <li>
                                        {' '}
                                        осуществление внутреннего контроля и (или) аудита
                                        соответствия обработки персональных данных Закону №152-ФЗ и
                                        принятым в соответствии с ним нормативным правовым актам,
                                        требованиям к защите персональных данных, политике Компании
                                        в отношении обработки персональных данных, внутренним
                                        документам Компании;
                                    </li>
                                    <li>
                                        {' '}
                                        оценка вреда, который может быть причинен субъектам
                                        персональных данных в случае нарушения Закона №152-ФЗ,
                                        соотношение указанного вреда и принимаемых Компанией мер,
                                        направленных на обеспечение выполнения обязанностей,
                                        предусмотренных Законом №152-ФЗ;
                                    </li>
                                    <li>
                                        {' '}
                                        исполнение процедур, необходимых для соблюдения
                                        законодательства в области противодействии легализации
                                        (отмыванию) доходов, полученных преступным путем, и
                                        финансированию терроризма и законодательства в области
                                        цифровых финансовых активов;{' '}
                                    </li>
                                    <li>
                                        {' '}
                                        ознакомление работников Компании, непосредственно
                                        осуществляющих обработку персональных данных, с положениями
                                        законодательства Российской Федерации о персональных данных,
                                        в том числе требованиями к защите персональных данных,
                                        документами, определяющими политику Компании в отношении
                                        обработки персональных данных, локальными актами по вопросам
                                        обработки персональных данных, и (или) обучение указанных
                                        работников.
                                    </li>
                                </ol>
                            </li>
                            <li>
                                Компания обязана публиковать на Сайтах, принадлежащих Компании в
                                информационно-телекоммуникационной сети «Интернет», с использованием
                                которых осуществляется сбор персональных данных, документ,
                                определяющий его политику в отношении обработки персональных данных,
                                и сведения о реализуемых требованиях к защите персональных данных, а
                                также обеспечить возможность доступа к указанному документу с
                                использованием средств соответствующей информационно -
                                телекоммуникационной сети.
                            </li>
                        </AgreementSection>
                        <AgreementSection title="МЕРЫ, НАПРАВЛЕННЫЕ НА ОБЕСПЕЧЕНИЕ БЕЗОПАСНОСТИ ПЕРСОНАЛьНЫХ ДАННЫХ">
                            <li>
                                Компания принимает необходимые правовые, организационные и
                                технические меры для защиты персональных данных от неправомерного
                                или случайного доступа к ним, уничтожения, изменения, блокирования,
                                копирования, предоставления, распространения персональных данных, а
                                также от иных неправомерных действий в отношении персональных данных
                                в соответствии с требованиями законодательства Российской Федерации
                                и внутренними документами. Обеспечение безопасности персональных
                                данных достигается, в частности:
                                <ol className="subparagraph-list">
                                    <li>
                                        назначение лица, ответственного за обеспечение безопасности
                                        персональных данных в информационных системах персональных
                                        данных;{' '}
                                    </li>
                                    <li>
                                        {' '}
                                        определением угроз безопасности персональных данных при их
                                        обработке в информационных системах персональных данных;
                                    </li>
                                    <li>
                                        {' '}
                                        применением организационных и технических мер по обеспечению
                                        безопасности персональных данных при их обработке в
                                        информационных системах персональных данных, необходимых для
                                        выполнения требований к защите персональных данных,
                                        исполнение которых обеспечивает установленные Правительством
                                        Российской Федерации уровни защищенности персональных
                                        данных;
                                    </li>
                                    <li>
                                        {' '}
                                        применением прошедших в установленном порядке процедуру
                                        оценки соответствия средств защиты информации;{' '}
                                    </li>
                                    <li>
                                        {' '}
                                        оценкой эффективности принимаемых мер по обеспечению
                                        безопасности персональных данных до ввода в эксплуатацию
                                        информационной системы персональных данных;
                                    </li>
                                    <li> учетом машинных носителей персональных данных;</li>
                                    <li>
                                        {' '}
                                        обнаружением фактов несанкционированного доступа к
                                        персональным данным и принятием мер, в том числе мер по
                                        обнаружению, предупреждению и ликвидации последствий
                                        компьютерных атак на информационные системы персональных
                                        данных и по реагированию на компьютерные инциденты в них;
                                    </li>
                                    <li>
                                        {' '}
                                        восстановлением персональных данных, модифицированных или
                                        уничтоженных вследствие несанкционированного доступа к ним;
                                    </li>
                                    <li>
                                        {' '}
                                        установлением правил доступа к персональным данным,
                                        обрабатываемым в информационной системе персональных данных,
                                        а также обеспечением регистрации и учета всех действий,
                                        совершаемых с персональными данными в информационной системе
                                        персональных данных;
                                    </li>
                                    <li>
                                        {' '}
                                        контролем за принимаемыми мерами по обеспечению безопасности
                                        персональных данных и уровня защищенности информационных
                                        систем персональных данных.
                                    </li>
                                </ol>
                            </li>
                        </AgreementSection>
                        <AgreementSection title="ОСОБЕННОСТИ ОБРАБОТКИ ИНФОРМАЦИИ НА САЙТЕ">
                            <li>
                                {' '}
                                Политика распространяется на обработку персональных данных,
                                собранных Компанией с использованием Сайта, в том числе в результате
                                заполнения субъектом персональных данных электронным форм,
                                размещенных на Сайте.
                            </li>
                            <li>
                                {' '}
                                Пользование субъектом персональных данных Сайта, а также
                                предоставление субъектом персональных данных Компании своих
                                персональных данных с использованием Сайта означает согласие
                                субъекта с Политикой. В случае несогласия с условиями Политики лицо
                                должно прекратить использование Сайта.
                            </li>
                            <li>
                                {' '}
                                Компания не контролирует соблюдение условий конфиденциальности и
                                обработки персональных данных субъектов персональных данных
                                владельцами или пользователями других Сайтов, на которые субъект
                                персональных данных может перейти по ссылкам, доступным на Сайте, и
                                не несет ответственность за действия или бездействия владельцев или
                                пользователей таких Сайтов в области обработки персональных данных и
                                соблюдения конфиденциальности.
                            </li>
                        </AgreementSection>
                    </AgreementSectionList>
                </Box>
            }
        />
    )
}
